import React, { useEffect, useState, useContext, useCallback } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import Avatar from 'react-avatar-edit';
import { FaUserCircle, FaTimes } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Profile.module.css';
import { UserContext } from './UserContext';

const Profile = () => {
  const { user: contextUser, setUser: setContextUser } = useContext(UserContext);
  const [uniqueDays, setUniqueDays] = useState({
    'Trendy Factory': 0,
    'Trendy Boulder': 0,
    'Trendy Match': 0
  });
  const [trendyFactoryData, setTrendyFactoryData] = useState({
    dailyScore: 0,
    totalScore: 0,
    totalTime: 0,
    dailyEstimatedReward: '0 SAND',
    totalEstimatedReward: '0 SAND',
    totalFactoryReward: '0 SAND',
    position: 'N/A'
  });
  const [trendyBoulderData, setTrendyBoulderData] = useState({
    dailyScore: 0,
    totalScore: 0,
    totalTime: 0,
    totalDailyScore: 0,
    overallTotalScore: 0,
    position: 'N/A',
    uniqueDays: 0
  });
  const [trendyMatchData, setTrendyMatchData] = useState({
    dailyScore: 0,
    totalScore: 0,
    totalTime: 0,
    dailyEstimatedReward: '0 SAND',
    totalEstimatedReward: '0 SAND',
    position: 'N/A'
  });
  const [totalEarnings, setTotalEarnings] = useState('0 SAND');
  const [totalPlaytime, setTotalPlaytime] = useState(0);
  const [averageDailyEarnings, setAverageDailyEarnings] = useState('0 SAND');
  const [notifyLeaderboardUpdates, setNotifyLeaderboardUpdates] = useState(false);
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isAvatarEditorOpen, setIsAvatarEditorOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [wallet, setWallet] = useState('');
  const [walletError, setWalletError] = useState('');
  const [progressBarData, setProgressBarData] = useState([
    { percentage: 0, color: '#4285F4' },
    { percentage: 0, color: '#34A853' },
    { percentage: 0, color: '#FBBC05' }
  ]);
  const { user: loggedInUser } = useContext(UserContext);

  const TRENDY_BOULDER_TOTAL_PRIZE = 1200;

  const calculateTrendyBoulderReward = useCallback((score, totalScore) => {
    return totalScore > 0 ? ((score / totalScore) * TRENDY_BOULDER_TOTAL_PRIZE).toFixed(2) : '0.00';
  }, [TRENDY_BOULDER_TOTAL_PRIZE]);

  const fetchWithRefresh = async (url, options) => {
    let response = await fetch(url, options);
    if (response.status === 403) {
      const refreshToken = localStorage.getItem('refreshToken');
      const refreshResponse = await fetch('/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: refreshToken })
      });
      if (refreshResponse.ok) {
        const { accessToken } = await refreshResponse.json();
        localStorage.setItem('token', accessToken);
        options.headers['Authorization'] = `Bearer ${accessToken}`;
        response = await fetch(url, options);
      }
    }
    return response;
  };

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetchWithRefresh('/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('User data received:', data);
        console.log('Avatar URL from server:', data.avatar_url);
        setContextUser(data);
        setAvatar(data.avatar_url);
        setWallet(data.wallet);
        setNotifyLeaderboardUpdates(Boolean(data.notify_leaderboard_updates));
      } else {
        console.error('Error fetching user data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [setContextUser]);

  const fetchGameData = async (tsb_uid) => {
    try {
      const [factoryData, boulderData, matchData] = await Promise.all([
        fetchFactoryData(tsb_uid),
        fetchBoulderData(tsb_uid),
        fetchMatchData(tsb_uid)
      ]);

      setTrendyFactoryData(factoryData);
      setTrendyBoulderData(boulderData);
      setTrendyMatchData(matchData);

      updateEarningsAndPlaytime(factoryData, boulderData, matchData);
    } catch (error) {
      console.error('Error fetching game data:', error);
    }
  };

  const handleNotificationChange = useCallback(() => {
    setNotifyLeaderboardUpdates(prevState => {
      const newNotifyStatus = !prevState;
  
      const updateServer = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            console.error('No token found');
            toast.error('Authentication error. Please log in again.');
            return prevState;
          }
  
          console.log('Sending update request:', newNotifyStatus);
  
          const response = await fetchWithRefresh('/api/update-notifications', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ notify_leaderboard_updates: newNotifyStatus ? 1 : 0 })
          });
  
          const data = await response.json();
  
          if (response.ok) {
            console.log('Server response:', data);
            toast.success('Notification preferences updated.');
            fetchUserData();  // Refresh user data
          } else {
            console.error('Failed to update notification preferences:', data);
            toast.error(`Failed to update notification preferences: ${data.error || 'Unknown error'}`);
            return prevState;
          }
        } catch (error) {
          console.error('Error updating notification preferences:', error);
          toast.error(`Error updating notification preferences: ${error.message}`);
          return prevState;
        }
      };
  
      updateServer();
      return newNotifyStatus;
    });
  }, [fetchUserData]);

  const setFakeData = useCallback(() => {
    console.log('Setting fake data...');
    
    // Only update bc2_earnings if they don't exist yet
    if (!contextUser.bc2_earnings) {
      setContextUser(prevUser => ({
        ...prevUser,
        bc2_earnings: 1250.75,
        bc2_earnings_note: "BC2 Earnings: 1250.75 SAND (total estimated from previous event - Factory: 500 SAND, Boulder: 300.75 SAND, Match: 450 SAND)",
      }));
    }

    // Set all current earnings to 0
    setTotalEarnings('0 SAND');
    setTotalPlaytime(0);
    setAverageDailyEarnings('0 SAND');

    // Single progress bar segment
    setProgressBarData([
      {
        percentage: 0,
        color: '#34A853' // Green color
      }
    ]);

    console.log('Fake data set complete');
  }, [contextUser, setContextUser]);

  const filterEvents = (days) => {
    const filteredDays = { ...days };
    delete filteredDays['Event'];
    return filteredDays;
  };

  const fetchFactoryData = async (tsb_uid) => {
    try {
      const response = await fetchWithRefresh(`/api/leaderboard-position/Trendy%20Factory/${tsb_uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        return {
          dailyScore: 0, // We don't have this information in the new table
          totalScore: data.totalScore || 0,
          totalTime: parseInt(data.totalTime, 10) || 0,
          dailyEstimatedReward: '0 SAND', // We don't have this information in the new table
          totalEstimatedReward: data.totalEstimatedReward || '0 SAND',
          totalFactoryReward: data.totalFactoryReward || '0 SAND',
          position: 'N/A', // We don't have this information in the new table
          uniqueDays: data.uniqueDays || 0,
          avgDailyEarnings: data.avgDailyEarnings || '0 SAND'
        };
      } else {
        console.error('Error fetching factory data:', response.statusText);
        return {
          dailyScore: 0,
          totalScore: 0,
          totalTime: 0,
          dailyEstimatedReward: '0 SAND',
          totalEstimatedReward: '0 SAND',
          totalFactoryReward: '0 SAND',
          position: 'N/A',
          uniqueDays: 0,
          avgDailyEarnings: '0 SAND'
        };
      }
    } catch (error) {
      console.error('Error fetching factory data:', error);
      return {
        dailyScore: 0,
        totalScore: 0,
        totalTime: 0,
        dailyEstimatedReward: '0 SAND',
        totalEstimatedReward: '0 SAND',
        totalFactoryReward: '0 SAND',
        position: 'N/A',
        uniqueDays: 0,
        avgDailyEarnings: '0 SAND'
      };
    }
  };

  const fetchBoulderData = async (tsb_uid) => {
    try {
      const response = await fetchWithRefresh(`/api/leaderboard-position/Trendy%20Boulder/${tsb_uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        return {
          dailyScore: data.dailyScore || 0,
          totalScore: data.totalScore || 0,
          totalTime: parseInt(data.totalTime, 10) || 0,
          totalDailyScore: data.totalDailyScore || 0,
          overallTotalScore: data.overallTotalScore || 0,
          position: data.position,
          uniqueDays: data.uniqueDays || 0,
          calculatedReward: data.totalEstimatedReward || '0 SAND'
        };
      } else {
        console.error('Error fetching boulder data:', response.statusText);
        return {
          dailyScore: 0,
          totalScore: 0,
          totalTime: 0,
          totalDailyScore: 0,
          overallTotalScore: 0,
          position: 'N/A',
          uniqueDays: 0,
          calculatedReward: '0 SAND'
        };
      }
    } catch (error) {
      console.error('Error fetching boulder data:', error);
      return {
        dailyScore: 0,
        totalScore: 0,
        totalTime: 0,
        totalDailyScore: 0,
        overallTotalScore: 0,
        position: 'N/A',
        uniqueDays: 0,
        calculatedReward: '0 SAND'
      };
    }
  };

  const fetchMatchData = async (tsb_uid) => {
    try {
      const leaderboardResponse = await fetchWithRefresh(`/api/leaderboard-trendy-match?limit=15`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!leaderboardResponse.ok) {
        console.error('Error fetching leaderboard data:', leaderboardResponse.statusText);
        throw new Error('Error fetching leaderboard data');
      }

      const leaderboardData = await leaderboardResponse.json();
      const userEntry = leaderboardData.find(player => player.name === tsb_uid) || {};

      const userResponse = await fetchWithRefresh(`/api/leaderboard-position/Trendy%20Match/${tsb_uid}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!userResponse.ok) {
        console.error('Error fetching user data:', userResponse.statusText);
        throw new Error('Error fetching user data');
      }

      const userData = await userResponse.json();

      return {
        dailyScore: userEntry.daily_score || 0,
        totalScore: userEntry.total_score || 0,
        totalTime: parseInt(userData.totalTime, 10) || 0,
        totalEstimatedReward: userEntry.estimatedReward ? `${userEntry.estimatedReward} SAND` : '0 SAND',
        position: userEntry.position || 'N/A',
        uniqueDays: userData.uniqueDays || 0
      };
    } catch (error) {
      console.error('Error fetching match data:', error);
      return {
        dailyScore: 0,
        totalScore: 0,
        totalTime: 0,
        totalEstimatedReward: '0 SAND',
        position: 'N/A',
        uniqueDays: 0
      };
    }
  };

  const updateProgressBarData = (factoryData, boulderData, matchData, totalEarningsValue) => {
    const factoryReward = parseFloat(factoryData.totalFactoryReward) || 0;
    const boulderReward = parseFloat(boulderData.calculatedReward || 300.75); // Use hardcoded value for admin
    const matchReward = parseFloat(matchData.totalEstimatedReward) || 0;
    const total = factoryReward + boulderReward + matchReward;

    if (total === 0) {
      setProgressBarData([
        { percentage: 33.3, color: '#4285F4' },
        { percentage: 33.3, color: '#34A853' },
        { percentage: 33.3, color: '#FBBC05' }
      ]);
    } else {
      setProgressBarData([
        { percentage: ((factoryReward / total) * 100).toFixed(1), color: '#4285F4' },
        { percentage: ((boulderReward / total) * 100).toFixed(1), color: '#34A853' },
        { percentage: ((matchReward / total) * 100).toFixed(1), color: '#FBBC05' }
      ]);
    }
  };

  const initializeData = async () => {
    if (!contextUser) {
      await fetchUserData();
      return;
    }

    // Set avatar and wallet data
    setAvatar(contextUser.avatar_url);
    setWallet(contextUser.wallet);
    setNotifyLeaderboardUpdates(Boolean(contextUser.notify_leaderboard_updates));

    // Check for admin and set fake data
    if (contextUser.is_admin) {
      console.log('Setting fake data for admin');
      setFakeData();
    } else {
      console.log('Fetching real data for user:', contextUser.tsb_uid);
      await fetchGameData(contextUser.tsb_uid);
    }
  };

  useEffect(() => {
    initializeData();
  }, [contextUser, fetchUserData, setFakeData]);

  useEffect(() => {
    const noticeAccepted = localStorage.getItem('noticeAccepted');
    if (!noticeAccepted) {
      setIsNoticeModalOpen(true);
    }
  }, []);

  const handleAcceptNotice = () => {
    localStorage.setItem('noticeAccepted', 'true');
    setIsNoticeModalOpen(false);
  };

  const resetLeaderboard = async () => {
    if (window.confirm('Are you sure you want to reset the leaderboard? This action cannot be undone.')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetchWithRefresh('/api/reset-leaderboard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (response.ok) {
          toast.success('Leaderboard reset successfully');
        } else {
          toast.error('Failed to reset leaderboard');
        }
      } catch (error) {
        console.error('Error resetting leaderboard:', error);
        toast.error('Error resetting leaderboard');
      }
    }
  };

  const updateEarningsAndPlaytime = useCallback((factoryData, boulderData, matchData) => {
    const estimatedFactoryTotalReward = parseFloat(factoryData.totalFactoryReward) || 0;
    const estimatedBoulderTotalReward = parseFloat(boulderData.calculatedReward || 300.75); // Use hardcoded value for admin
    const estimatedMatchTotalReward = parseFloat(matchData.totalEstimatedReward) || 0;
  
    const totalEarningsValue = (
      estimatedFactoryTotalReward +
      estimatedBoulderTotalReward +
      estimatedMatchTotalReward
    ).toFixed(2);
  
    setTotalEarnings(`${totalEarningsValue} SAND`);
  
    const filteredUniqueDays = filterEvents({
      'Trendy Factory': factoryData.uniqueDays || 0,
      'Trendy Boulder': boulderData.uniqueDays || 0,
      'Trendy Match': matchData.uniqueDays || 0
    });
    setUniqueDays(filteredUniqueDays);
  
    const totalUniqueDays = Object.values(filteredUniqueDays).reduce((sum, days) => sum + days, 0);
  
    const totalPlaytimeValue = (
      (factoryData.totalTime || 0) + 
      (boulderData.totalTime || 0) + 
      (matchData.totalTime || 0)
    );
    setTotalPlaytime(totalPlaytimeValue);
  
    const avgDailyEarnings = totalUniqueDays > 0 ? (totalEarningsValue / totalUniqueDays).toFixed(2) : '0.00';
    setAverageDailyEarnings(`${avgDailyEarnings} SAND`);
  
    updateProgressBarData(factoryData, boulderData, matchData, totalEarningsValue);
  }, []);

  const base64StringToBlob = (base64, mimeType) => {
    const byteString = atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; byteString.length > i; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeType });
  };

  const handleAvatarSave = async () => {
    if (preview) {
      const mimeType = 'image/png';
      const avatarBlob = base64StringToBlob(preview, mimeType);
      const formData = new FormData();
      formData.append('avatar', avatarBlob, 'avatar.png');

      try {
        const token = localStorage.getItem('token');
        const response = await fetchWithRefresh('/api/upload-avatar', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Avatar URL response:', data.avatarUrl);
          setAvatar(data.avatarUrl);
          fetchUserData(); 
          setIsAvatarEditorOpen(false);
          toast.success('Avatar updated successfully.');
        } else {
          toast.error('Failed to upload avatar.');
        }
      } catch (error) {
        console.error('Error uploading avatar:', error);
        toast.error('Error uploading avatar.');
      }
    }
  };

  const onClose = () => {
    setPreview(null);
    setIsAvatarEditorOpen(false);
    setIsWalletModalOpen(false);
    setWallet('');
    setWalletError('');
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 5 * 1024 * 1024) {
      toast.error('File is too large. Please upload a file smaller than 5MB.');
      elem.target.value = '';
    }
  };

  const handleWalletSave = async () => {
    setWalletError('');
    
    if (!wallet.trim()) {
      setWalletError('Please enter a valid Wallet ID.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetchWithRefresh('/api/save-wallet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ wallet: wallet.trim() })
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Wallet ID updated successfully.');
        fetchUserData();  // Refresh user data
        setIsWalletModalOpen(false);
        setWallet('');
        setWalletError('');
      } else {
        if (response.status === 400 && data.error) {
          setWalletError(data.error);
          setWallet('');
        } else {
          setWalletError('Failed to update wallet ID.');
        }
      }
    } catch (error) {
      console.error('Error updating wallet ID:', error);
      setWalletError('Error updating wallet ID.');
    }
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds === null) return '00:00';
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    return h >= 100 ? `${h}:${m.toString().padStart(2, '0')}` : `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
  };

  const EarningsOverview = () => {
    return (
      <div className={styles.earningsOverview}>
        <h2>Earnings Overview</h2>
        <div className={styles.avatarContainer}>
          <div className={styles.avatarWrapper} onClick={() => setIsAvatarEditorOpen(true)}>
          {avatar ? (
              <>
                <img 
                  src={avatar}
                  alt="Avatar" 
                  className={styles.avatar}
                  onError={(e) => {
                    console.error('Image failed to load:', e.target.src);
                    console.error('Current avatar state:', avatar);
                    e.target.onerror = null;
                    setAvatar(null); 
                  }}
                />
              </>
            ) : (
              <FaUserCircle className={styles.avatarIcon} />
            )}
            <div className={styles.editAvatarIcon}>
              <span>+</span>
            </div>
          </div>
          <div className={styles.avatarName}>{contextUser?.tsb_uid}</div>
        </div>
        <p className={styles.subtext}>Total earnings</p>
        <div className={styles.earningsMain}>
          <span className={styles.totalEarnings}>
            <img src="/images/SAND.png" alt="SAND icon" className={styles.sandIcon} /> {totalEarnings}
          </span>
        </div>

        <div className={styles.progressBar}>
          {progressBarData.map((segment, index) => (
            <div
              key={index}
              className={styles.progressSegment}
              style={{ width: '100%', backgroundColor: segment.color }}
            >
              0 / 100%
            </div>
          ))}
        </div>

        <div className={styles.statsContainer}>
          <div>
            <p className={styles.subtext}>Total playtime</p>
            <p className={styles.statValue}>00:00 hours</p>
          </div>
          <div>
            <p className={styles.subtext}>Avg. daily earnings</p>
            <p className={styles.statValue}>
              <img src="/images/SAND.png" alt="SAND icon" className={styles.sandIcon} /> {averageDailyEarnings}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.profileContainer}>
      <ToastContainer />
      <EarningsOverview />
      <div className={styles.notificationToggle}>
        <label>
          <input
            type="checkbox"
            checked={notifyLeaderboardUpdates}
            onChange={handleNotificationChange}
          />
          Notify me about leaderboard updates
        </label>
      </div>
      <div className={styles.bottomContainer}>
        <button
          className={styles.addWalletButton}
          onClick={() => setIsWalletModalOpen(true)}
        >
          {wallet ? 'Edit Wallet ID' : 'Add Wallet ID'}
        </button>
        {wallet && <span className={styles.walletDisplay}>{wallet}</span>}
      </div>
      {/*} {loggedInUser?.is_admin && (
        <div className={styles.adminActions}>
          <button onClick={resetLeaderboard} className={`${styles.addWalletButton} ${styles.resetButton}`}>
            Reset Leaderboard
          </button>
        </div>
      )}*/}

      {/* History Section */}
      <div className={styles.history}>
        <h2 className={styles.historyTitle}>History</h2>
        {contextUser?.bc2_earnings && (
          <div className={styles.bc2Earnings}>
            <div className={styles.bc2Amount}>
              <img src="/images/SAND.png" alt="SAND" style={{ width: '24px', height: '24px' }} />
              {contextUser.bc2_earnings.toFixed(2)} SAND
            </div>
            <div className={styles.bc2Note}>
              BC2 Earnings: {contextUser.bc2_earnings} SAND (total estimated from previous event - Factory: 500 SAND, Boulder: 300.75 SAND, Match: 450 SAND)
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isNoticeModalOpen}
        onRequestClose={handleAcceptNotice}
        contentLabel="Important Notice"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Important Notice about Sand Rewards</h2>
        <p>
          Please be aware that the Sand rewards for the Builder's Challenge are estimates based on past challenge experiences.
          The actual prize pool is subject to change and may fluctuate significantly every two weeks until the end of the Builder's Challenge.
        </p>
        <button onClick={handleAcceptNotice} className={styles.acceptButton}>Accept</button>
      </Modal>
      <Modal
        isOpen={isAvatarEditorOpen}
        onRequestClose={onClose}
        contentLabel="Edit Avatar"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h2>Edit Avatar</h2>
          <button onClick={onClose} className={styles.closeButton}><FaTimes /></button>
        </div>
        <Avatar
          width={390}
          height={295}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
        />
        <button onClick={handleAvatarSave} className={styles.acceptButton}>Save</button>
      </Modal>
      <Modal
        isOpen={isWalletModalOpen}
        onRequestClose={onClose}
        contentLabel="Edit Wallet"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h2>Edit Wallet</h2>
          <button onClick={onClose} className={styles.closeButton}><FaTimes /></button>
        </div>
        <p><strong>Current Wallet ID:</strong> <span>{contextUser?.wallet || 'No Wallet ID'}</span></p>
        <input
          type="text"
          value={wallet}
          onChange={(e) => setWallet(e.target.value)}
          className={`${styles.walletInput} ${walletError ? styles.errorInput : ''}`}
          placeholder="Enter Polygon Wallet ID"
        />
        {walletError && <p className={styles.errorText}>{walletError}</p>}
        <button onClick={handleWalletSave} className={styles.acceptButton}>Save</button>
      </Modal>
    </div>
  );
};

export default Profile;