import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarFramer from './components/NavbarFramer';
import Profile from './components/Profile';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Review from './components/Review';
import EventsAdmin from './components/EventsAdmin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './components/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <NavbarFramer />
        <Routes>
          <Route path="/" element={<Profile />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/review" element={<Review />} />
          <Route path="/events-admin" element={<EventsAdmin />} />
        </Routes>
        <ToastContainer />
      </Router>
    </UserProvider>
  );
}

export default App;