import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import styles from './NavbarFramer.module.css';
import { toast } from 'react-toastify';

const NavbarFramer = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Ensure session is initialized

    const isAdmin = user?.is_admin;

    // Utility to clear cookies
    const clearCookie = (name, domain) => {
        // Clear cookie with specific domain
        document.cookie = `${name}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
        // Also clear cookie without domain specification (for localhost/IP access)
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
    };

    const handleLogout = () => {
        // Clear all localStorage items
        localStorage.clear();
      
        // Clear all sessionStorage items
        sessionStorage.clear();
      
        // Clear cookies for all relevant domains
        const domains = ['.trendynerds.top', 'app.trendynerds.top', 'trendynerds.top'];
        const cookiesToClear = ['token', 'refreshToken', 'user', 'tsb_uid', 'tsb_token', 'lastAuthUpdate'];
      
        domains.forEach(domain => {
          cookiesToClear.forEach(cookieName => {
            document.cookie = `${cookieName}=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
            document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=none`;
          });
        });
      
        // Notify other tabs/windows
        window.dispatchEvent(new StorageEvent('storage', {
          key: 'lastAuthUpdate',
          newValue: Date.now().toString()
        }));
      
        // Notify parent window if in iframe
        if (window.parent !== window) {
          try {
            window.parent.postMessage({
              type: 'authStateChanged',
              action: 'clearStorage'
            }, 'https://app.trendynerds.top');
            window.parent.localStorage.clear();
            window.parent.sessionStorage.clear();
          } catch (error) {
            console.warn('Could not notify parent window:', error);
          }
        }
      
        // Redirect to main site
        window.location.href = 'https://trendynerds.top';
        toast.success('Logged out successfully!');
      };

    const handleBack = () => {
        window.location.href = 'https://trendynerds.top';
    };

    // Wait for session initialization
    useEffect(() => {
        const initializeSession = () => {
            if (user !== null) {
                setLoading(false); // Session is initialized
            }
        };
        initializeSession();
    }, [user]);

    if (loading) {
        // Show nothing while waiting for session initialization
        return null;
    }

    if (!user) {
        // Avoid abrupt redirect during initialization
        navigate('https://trendynerds.top');
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <nav className={styles.nav}>
                <div className={styles.navCenter}>
                    <Link 
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleBack();
                        }}
                        className={styles.navLink}
                    >
                        &lt; back
                    </Link>
                    {isAdmin && (
                        <Link 
                            to="/review"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/review');
                            }}
                            className={styles.navLink}
                        >
                            review
                        </Link>
                    )}
                    {isAdmin && (
                        <Link 
                            to="/"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/');
                            }}
                            className={styles.navLink}
                        >
                            dashboard
                        </Link>
                    )}
                    {isAdmin && (
                        <Link 
                            to="/events-admin"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/events-admin');
                            }}
                            className={styles.navLink}
                        >
                            events
                        </Link>
                    )}
                    <Link 
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                        }}
                        className={styles.navLink}
                    >
                        log out
                    </Link>
                </div>
            </nav>
            <div className={styles.separator}></div>
        </div>
    );
};

export default NavbarFramer;
