import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faTrash, faSpinner, faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './EventsAdmin.module.css';

const EventsAdmin = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        nft_contract: '0xDbc52cd5b8EdA1A7BCBABb838ca927d23E3673e5', // Default Sandbox contract
        token_id: '',
        registration_start: '',
        registration_end: '',
        max_participants: '',
        ticket_price: '',
        game_url: '' // URL for the game when event is active
    });
    const [loading, setLoading] = useState(true);
    const [testingNFT, setTestingNFT] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedWinner, setSelectedWinner] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchParticipants = async (eventId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You need to be logged in as an admin');
                return;
            }

            const response = await fetch(`https://app.trendynerds.top/api/events/${eventId}/participants`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch participants');
            }

            const data = await response.json();
            setParticipants(data || []);
        } catch (error) {
            console.error('Error fetching participants:', error);
            toast.error('Failed to load participants');
        }
    };

    const fetchEvents = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://app.trendynerds.top/api/admin/events', {
                headers: {
                    'Authorization': token ? `Bearer ${token}` : ''
                },
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Failed to load events');
            }
            const data = await response.json();
            setEvents(data.events || []);
        } catch (error) {
            console.error('Error fetching events:', error);
            toast.error('Failed to load events');
        } finally {
            setLoading(false);
        }
    };

    const testNFTContract = async () => {
        setTestingNFT(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You need to be logged in');
                return;
            }

            const response = await fetch('https://app.trendynerds.top/api/events/check-nft', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({
                    contractAddress: formData.nft_contract,
                    tokenId: formData.token_id
                })
            });
            
            const data = await response.json();
            if (data.error) {
                toast.error('Failed to verify NFT contract');
            } else {
                toast.success(`NFT Contract verified! Found ${data.totalOwned} NFTs`);
            }
        } catch (error) {
            toast.error('Failed to test NFT contract');
        } finally {
            setTestingNFT(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You need to be logged in as an admin');
                return;
            }

            const response = await fetch('/api/events', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({
                    ...formData,
                    status: 'upcoming'
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create event');
            }

            const data = await response.json();
            if (data.success) {
                toast.success('Event created successfully!');
                
                // Clear form
                setFormData({
                    title: '',
                    description: '',
                    nft_contract: '0xDbc52cd5b8EdA1A7BCBABb838ca927d23E3673e5',
                    token_id: '',
                    registration_start: '',
                    registration_end: '',
                    max_participants: '',
                    ticket_price: ''
                });
                
                // Refresh events list
                fetchEvents();
            }
        } catch (error) {
            console.error('Error creating event:', error);
            toast.error(error.message || 'Failed to create event');
        }
    };



    const handleCompleteEvent = async (eventId) => {
        try {
            setSelectedEventId(eventId);
            setShowParticipants(true);
            await fetchParticipants(eventId);
        } catch (error) {
            console.error('Error handling complete event:', error);
            toast.error('Failed to load participants');
            setShowParticipants(false);
            setSelectedEventId(null);
            setSelectedWinner(null);
        }
    };

    const handleShowParticipants = async (eventId) => {
        try {
            setSelectedEventId(eventId);
            setShowParticipants(true);
            await fetchParticipants(eventId);
        } catch (error) {
            console.error('Error loading participants:', error);
            toast.error('Failed to load participants');
            setShowParticipants(false);
            setSelectedEventId(null);
        }
    };

    const handleDeclareWinner = async () => {
        if (!selectedWinner || !selectedEventId) {
            toast.error('Please select a winner first');
            return;
        }
        await completeEvent(selectedEventId, selectedWinner.username);
    };

    const completeEvent = async (eventId, tsb_uid) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You need to be logged in as an admin');
                return;
            }

            const response = await fetch(`https://app.trendynerds.top/api/events/${eventId}/complete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: JSON.stringify({ tsb_uid })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to complete event');
            }

            const data = await response.json();
            if (data.success) {
                toast.success(`Event completed! Winner: ${tsb_uid}`);
                setShowParticipants(false);
                setSelectedEventId(null);
                setSelectedWinner(null);
                setSearchTerm('');
                fetchEvents();
            }
        } catch (error) {
            console.error('Error completing event:', error);
            toast.error(error.message || 'Failed to complete event');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDeleteEvent = async (eventId) => {
        if (!window.confirm('Are you sure you want to delete this event?')) return;

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error('You need to be logged in as an admin');
                return;
            }

            const response = await fetch(`https://app.trendynerds.top/api/events/${eventId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete event');
            }
            
            toast.success('Event deleted successfully!');
            fetchEvents();
        } catch (error) {
            console.error('Error deleting event:', error);
            toast.error('Failed to delete event');
        }
    };

    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <FontAwesomeIcon icon={faSpinner} spin className={styles.spinner} />
                <p>Loading events...</p>
            </div>
        );
    }

    return (
        <div className={styles.eventsAdminContainer}>
            <div className={styles.createEventSection}>
                <h2 className={styles.sectionTitle}>
                    <FontAwesomeIcon icon={faCalendarPlus} className={styles.titleIcon} />
                    Create New Event
                </h2>
                <div className={styles.sandboxInfo}>
                    <h3>Sandbox NFT Information</h3>
                    <p>Contract Address: 0xDbc52cd5b8EdA1A7BCBABb838ca927d23E3673e5</p>
                    <p>Network: Polygon (MATIC)</p>
                </div>
                <form onSubmit={handleSubmit} className={styles.eventForm}>
                    <div className={styles.formGrid}>
                        <div className={styles.formGroup}>
                            <label>Title</label>
                            <input
                                type="text"
                                value={formData.title}
                                onChange={handleChange}
                                name="title"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>NFT Token ID</label>
                            <input
                                type="text"
                                value={formData.token_id}
                                onChange={handleChange}
                                name="token_id"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Registration Start</label>
                            <input
                                type="datetime-local"
                                value={formData.registration_start}
                                onChange={handleChange}
                                name="registration_start"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Registration End</label>
                            <input
                                type="datetime-local"
                                value={formData.registration_end}
                                onChange={handleChange}
                                name="registration_end"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Max Participants</label>
                            <input
                                type="number"
                                value={formData.max_participants}
                                onChange={handleChange}
                                name="max_participants"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Game URL</label>
                            <input
                                type="url"
                                value={formData.game_url}
                                onChange={handleChange}
                                name="game_url"
                                placeholder="https://game-url.com"
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Ticket Price</label>
                            <input
                                type="number"
                                value={formData.ticket_price}
                                onChange={handleChange}
                                name="ticket_price"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.formGroup}>
                        <label>Description</label>
                        <textarea
                            value={formData.description}
                            onChange={handleChange}
                            name="description"
                            required
                            className={styles.textarea}
                            rows={4}
                        />
                    </div>
                    <div className={styles.buttonGroup}>
                        <button
                            type="button"
                            onClick={testNFTContract}
                            className={styles.testButton}
                            disabled={testingNFT}
                        >
                            {testingNFT ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin /> Testing NFT...
                                </>
                            ) : (
                                'Test NFT Contract'
                            )}
                        </button>
                        <button type="submit" className={styles.submitButton}>Create Event</button>
                    </div>
                </form>
            </div>

            {showParticipants && (
                <div className={styles.participantsModal}>
                    <div className={styles.modalContent}>
                        <h3>Select Winner</h3>
                        <div className={styles.searchBox}>
                            <FontAwesomeIcon icon={faSearch} />
                            <input
                                type="text"
                                placeholder="Search participants..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className={styles.participantsList}>
                            {participants
                                .filter(p => p.username.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map(participant => (
                                    <div
                                        key={participant.id}
                                        className={`${styles.participantItem} ${selectedWinner?.id === participant.id ? styles.selected : ''}`}
                                        onClick={() => setSelectedWinner(participant)}
                                    >
                                        {participant.username}
                                    </div>
                                ))}
                        </div>
                        <div className={styles.modalActions}>
                            <button
                                className={styles.cancelButton}
                                onClick={() => {
                                    setShowParticipants(false);
                                    setSelectedEventId(null);
                                    setSelectedWinner(null);
                                    setSearchTerm('');
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={styles.completeButton}
                                onClick={handleDeclareWinner}
                                disabled={!selectedWinner}
                            >
                                Declare Winner
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.currentEventsSection}>
                <h2 className={styles.sectionTitle}>Current Events</h2>
                {events.length === 0 ? (
                    <p className={styles.noEventsMessage}>No events found.</p>
                ) : (
                    <div className={styles.eventsGrid}>
                        {events.map(event => (
                            <div key={event.id} className={styles.eventCard}>
                                <h3>{event.title}</h3>
                                <p className={styles.description}>{event.description}</p>
                                <div className={styles.eventDetails}>
                                    <p><strong>Token ID:</strong> {event.token_id}</p>
                                    <p><strong>Start:</strong> {new Date(event.registration_start).toLocaleString()}</p>
                                    <p><strong>End:</strong> {new Date(event.registration_end).toLocaleString()}</p>
                                    <p><strong>Max Participants:</strong> {event.max_participants}</p>
                                    <p><strong>Current Participants:</strong> {event.participants_count}</p>
                                    <p><strong>Ticket Price:</strong> {event.ticket_price} SAND</p>
                                    <p><strong>Prize Pool:</strong> {event.prize_pool.toFixed(2)} SAND</p>
                                </div>
                                <div className={styles.eventActions}>
                                    {event.status !== 'completed' && (
                                        <button
                                            onClick={() => handleCompleteEvent(event.id)}
                                            className={styles.completeRiddleButton}
                                        >
                                            <FontAwesomeIcon icon={faCheck} /> Complete Riddle
                                        </button>
                                    )}
                                    <button
                                        onClick={() => handleDeleteEvent(event.id)}
                                        className={styles.deleteButton}
                                    >
                                        <FontAwesomeIcon icon={faTrash} /> Delete Event
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EventsAdmin;